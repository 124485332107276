<script>
import { required } from "vuelidate/lib/validators";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
// import tableData from "@/components/tablecomponent";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";

// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "ใบจ่ายอะไหล่",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    // tableData,
    Multiselect,
    DatePicker,
  },
  // Multiselect, DatePicker,
  data() {
    return {
      // tableData: tableData,
      isLoading: false,
      errormessage: "กรุณากรอกข้อมูลให้ครบ",

      title: "ใบจ่ายอะไหล่",
      items: [
        {
          text: "หน้าหลัก",
        },
        {
          text: "ใบจ่ายอะไหล่",
          active: true,
        },
      ],
      userBranch: "",
      service: [],
      selected: [],
      optionBranch: [],
      rowsUser: [],
      rowsRequis: [],
      submitform: false,
      filter: {
        vin: "",
        licensePlate: "",
        mobilePhone: "",
        branch: "",
      },
      rows: [],
      userBranchId: "",
      pickingDate: [{reqDate: ""}],
      dcPer: "",
      vatPer: appConfig.vat,
      pickingId: "",
      reqCode: "",

      licensePlate: "",
      customerNameTh: "",
      customerFamilyNameTh: "",
      reqId: "",
      note: "",
      cusId: "",
      stockId: "",
      rowsStock: [],
      totalRecord: "",
      perPage: 10,
      currentPage: 1,
      sortBy: "age",
      sortDesc: false,
      filterOn: [],
      selectMode: "single",
      pageOptions: [5, 10, 25, 50, 100],
      loading: false,
      fields: [
        {
          key: "index",
          label: "ลำดับ",
        },
        {
          key: "reqCode",
          sortable: true,
          label: "reqCode",
        },
        {
          key: "customerNameTh",
          sortable: true,
          label: "ชื่อ",
        },
        {
          key: "customerFamilyNameTh",
          sortable: true,
          label: "นามสกุล",
        },
        {
          key: "licensePlate",
          sortable: true,
          label: "ป้ายทะเบียน",
        },
        {
          key: "grandTotalPrice",
          sortable: true,
          label: "ยอดรวม",
        },
        {
          key: "reqDate",
          sortable: true,
          label: "วันที่",
        },
        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
      ],
    };
  },
  validations: {
    pickingDate: {
      reqDate: {
        required,
      },
    },
    dcPer: {
      required,
    },
    vatPer: {
      required,
    },
  },
  computed: {},
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
    });
    this.userBranch = arrayBranch;
    this.optionBranch = user.userAccessBranch;
    this.userBranchId = user.branchId;
    this.getDataRequisitions();
    this.getDataCustomer();
    this.getDataStock();
  },
  created() {
    // this.getData();
    // this.getDataRequisitions();
  },
  methods: {
    getData: function() {
      useNetw
        .get("api/part-picking", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            // "branchId[]": this.filter.branch.branchId,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.rows.to = response.data.to;
          this.rows.from = response.data.from;
          this.rows.total = response.data.total;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    getDataStock: function() {
      useNetw
        .get("api/master/stock-part", {
          params: {
            branchId: this.userBranchId,
          },
        })
        .then((response) => {
          this.rowsStock = response.data.data;
          // this.rows.to = response.data.to;
          // this.rows.from = response.data.from;
          // this.rows.total = response.data.total;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    getDataRequisitions: function(select) {
      useNetw
        .get("api/part-picking/requisitions", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            // branchId: this.userBranchId,
            branchId: 7,
          },
        })
        .then((response) => {
          this.rowsRequis = response.data.data;
          // this.from = response.data.data;
          // this.perPage = response.data.data;
          this.totalRecord = response.data.total;

          if (select) {
            this.rowsRequis.forEach((item) => {
              this.dcPer = item.dcPer;
              this.vatPer = item.vatPer;
              this.licensePlate = item.licensePlate;
              this.customerNameTh = {
                nameTh: item.customerNameTh,
                familyNameTh: item.customerFamilyNameTh,
              };
              this.reqCode = {
                reqCode: item.reqCode,
                reqId: item.reqId,
              };
              this.pickingDate = {
                reqDate: item.reqDate
              }
              // this.customerFamilyNameTh = item.customerFamilyNameTh;
              this.reqId = item.reqId;
            });
          }
          // Swal.fire(
          //     appConfig.swal.title.error,
          //     JSON.stringify(appConfig.swal.title.Unauthorization),
          //     appConfig.swal.type.error
          //   );
          //   this.rows.to = response.data.to;
          //   this.rows.from = response.data.from;
          //   this.rows.total = response.data.total;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    getDataCustomer: function() {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/customer", {
          params: {
            page: 1,
            perPage: 100,
            branchId: this.userBranch,
          },
        })
        .then((response) => {
          this.rowsUser = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    submitPicking: function() {
      const bodyFormData = new FormData();
      if (this.reqCode.reqId != null) {
        bodyFormData.append("reqId", this.reqId);
      }
      bodyFormData.append("branchId", this.userBranchId);
      if (this.customerNameTh.cusId != null) {
        bodyFormData.append("cusId", this.customerNameTh.cusId);
      }

      if (this.stockId.stockId != null) {
        bodyFormData.append("stockId", this.stockId.stockId);
      }

      bodyFormData.append("pickingDate", this.pickingDate.reqDate);
      bodyFormData.append("dcPer", this.dcPer);
      bodyFormData.append("vatPer", this.vatPer);
      if (this.note != '') {
        bodyFormData.append("note", this.note);
      }
      useNetw
        .post("api/part-picking/store", bodyFormData)
        .then((response) => {
          this.pickingId = response.data.pickingId;
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({
            name: "detail-part-picking",
            params: { pickingId: this.pickingId },
          });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalService.hide();
        });
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getData();
    },
    onRowSelected(rows) {
      this.selected = rows;
      this.getDataRequisitions(true)
      this.$refs["modalSearchPicking"].hide();
    },
    handleSearch() {
      //   this.getData();
    },
    tooltipForm() {
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.$invalid != true) {
        this.submitPicking();
      }
    },
    customLabel({ nameTh, familyNameTh }) {
      return `${nameTh}  ${familyNameTh}`;
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="card-title">Service List</h4> -->

            <!-- Search -->
            <div class="row justify-content-md-center">
              <div class="col-sm-12 col-md-12">
                <div class="row align-items-center">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 mt-3">
                      <div class="row">
                        <div class="col-6 col-sm-6 col-md-3">
                          <div class="mb-3 position-relative">
                            <label>reqCode:</label>
                            <multiselect
                              :options="rowsRequis"
                              v-model="reqCode"
                              :close-on-select="true"
                              :internal-search="true"
                              open-direction="bottom"
                              @input="getDataRequisitions(true)"
                              :show-labels="false"
                              label="reqCode"
                              placeholder=""
                            >
                              <span slot="noResult">ไม่พบข้อมูล</span>
                            </multiselect>
                          </div>
                        </div>

                        <div class="col-6 col-sm-6 col-md-1">
                      <div class="mt-4 position-relative">
                        <b-button class="btn" variant="info" v-b-modal.modalSearchPicking>
                          <i class="uil-file-search-alt"></i
                        ></b-button>
                      </div>
                    </div>
                        <div class="col col-sm-6 col-md-4">
                          <div class="mb-3 position-relative">
                            <label>ป้ายทะเบียน:</label>
                            <input
                              v-model="licensePlate"
                              type="search"
                              class="form-control"
                              disabled
                            />
                          </div>
                        </div>
                        <div class="col col-sm-6 col-md-2">
                          <div class="mb-3 position-relative">
                            <label>ชื่อ:</label>
                            <multiselect
                              :options="rowsUser"
                              v-model="customerNameTh"
                              :close-on-select="true"
                              :internal-search="true"
                              :custom-label="customLabelName"
                              open-direction="bottom"
                              :show-labels="false"
                              label="nameTh"
                              placeholder=""
                            >
                              <span slot="noResult">ไม่พบข้อมูล</span>
                            </multiselect>
                            <!-- <input
                              v-model="customerNameTh"
                              type="search"
                              class="form-control"
                            /> -->
                          </div>
                        </div>
                        <!-- <div class="col col-sm-6 col-md-2">
                          <div class="mb-3 position-relative">
                            <label>สกุล:</label>
                            <input
                              v-model="customerFamilyNameTh"
                              type="search"
                              class="form-control"
                            />
                          </div>
                        </div> -->
                        <div class="col col-sm-6 col-md-2">
                          <div class="mb-3 position-relative">
                            <label>วันที่จ่ายอะไหล่:</label>
                            <date-picker
                              v-model="pickingDate.reqDate"
                              format="YYYY-MM-DD"
                              value-type="format"
                              :class="{
                                'is-invalid': submitform && $v.pickingDate.reqDate.$error,
                              }"
                            ></date-picker>
                            <div
                              v-if="submitform && $v.pickingDate.reqDate.$error"
                              class="invalid-tooltip"
                            >
                              <span v-if="!$v.pickingDate.reqDate.required">{{
                                errormessage
                              }}</span>
                            </div>
                          </div>  
                        </div>
                        <div class="col-6 col-sm-6 col-md-4">
                          <div class="mb-3 position-relative">
                            <label>คลังสินค้า:</label>
                            <multiselect
                              :options="rowsStock"
                              v-model="stockId"
                              :close-on-select="true"
                              :internal-search="true"
                              open-direction="bottom"
                              :show-labels="false"
                              label="nameTh"
                              placeholder=""
                            >
                              <span slot="noResult">ไม่พบข้อมูล</span>
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-2">
                          <div class="mb-3 position-relative">
                            <label>ส่วนลด %:</label>
                            <input
                              v-model="dcPer"
                              type="search"
                              class="form-control"
                              :class="{
                                'is-invalid': submitform && $v.dcPer.$error,
                              }"
                            />
                            <div
                              v-if="submitform && $v.dcPer.$error"
                              class="invalid-tooltip"
                            >
                              <span v-if="!$v.dcPer.required">{{
                                errormessage
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-2">
                          <div class="mb-3 position-relative">
                            <label>Vat (%):</label>
                            <input
                              v-model="vatPer"
                              type="search"
                              class="form-control"
                              disabled
                              :class="{
                                'is-invalid': submitform && $v.vatPer.$error,
                              }"
                            />
                            <div
                              v-if="submitform && $v.vatPer.$error"
                              class="invalid-tooltip"
                            >
                              <span v-if="!$v.vatPer.required">{{
                                errormessage
                              }}</span>
                            </div>
                          </div>
                        </div>

                        <div class="col-6 col-sm-6 col-md-4">
                          <div class="mb-3 position-relative">
                            <label>หมายเหตุ:</label>
                            <b-form-textarea v-model="note"></b-form-textarea>
                          </div>
                        </div>

                        <div class="col-6 col-sm-5 col-md-12">
                          <div class="mb-3 position-relative">
                            <b-button
                              class="btn mt-4 float-end"
                              variant="success"
                              type="submit"
                              @click="tooltipForm"
                            >
                              <!-- <i class="uil-search-alt"></i> -->
                              บันทึก
                            </b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
          </div>
        </div>
      </div>
    </div>

    <b-modal
      ref="modalSearchPicking"
      id="modalSearchPicking"
      title="รายการสั่งซ่อม"
      hide-footer
      size="xl"
      centered
    >
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 col-md-12 align-items-center">
            <div class="row align-items-center">
              <div class="col-6 col-sm-4 col-md-3">
                <label class="d-inline align-items-center m-2 text-end">
                  เลขที่ใบแจ้งซ่อม
                  <b-form-input
                    v-model="filter.roCode"
                    type="search"
                    placeholder="เลขที่ใบแจ้งซ่อม"
                    class="form-control ms-2"
                    @keyup.enter="handleSearch"
                  ></b-form-input>
                </label>
              </div>

              <div class="col-6 col-sm-4 col-md-3">
                <label class="d-inline align-items-center m-2">
                  ชื่อ-นามสกุลลูกค้า
                  <b-form-input
                    v-model="filter.customerFullNameTh"
                    type="search"
                    placeholder="ชื่อ-นามสกุล"
                    class="form-control ms-2"
                    @keyup.enter="handleSearch"
                  ></b-form-input>
                </label>
              </div>

              <div class="col-6 col-sm-4 col-md-3">
                <label class="d-inline align-items-center m-2">
                  ทะเบียนรถ
                  <b-form-input
                    v-model="filter.licensePlate"
                    type="search"
                    placeholder="ทะเบียนรถ"
                    class="form-control ms-2"
                    @keyup.enter="handleSearch"
                  ></b-form-input>
                </label>
              </div>

              <div class="col-6 col-md-3 col-sm-3">
                <div class="mb-4 position-relative">
                  <label class="d-inline align-items-center m-2">
                    วันที่สั่งซ่อม</label
                  >

                  <date-picker
                    v-model="filter.roDate"
                    :first-day-of-week="1"
                    format="YYYY-MM-DD"
                    @input="handleSearch"
                    value-type="format"
                    lang="en"
                  ></date-picker>
                </div>
              </div>

              <div
                class="col-2 col-sm-2 col-md-2 text-end"
                style="margin-left: auto; margin-right: 0"
              >
                <b-button
                  class="btn btn ms-2"
                  variant="info"
                  type="submit"
                  @click="handleSearch"
                >
                  <i class="uil-search"></i>
                  ค้นหา
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-skeleton-table
              :rows="5"
              :columns="6"
              :table-props="{ bordered: false, striped: true }"
              animation="throb"
            ></b-skeleton-table>
          </template>
          <div class="row mt-2">
            <div class="col-sm-12 col-md-12">
              <div class="row align-items-center">
                <div class="col-sm-5 col-md-3">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-block align-items-center">
                      แสดงผล
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                        @input="handlePageChange"
                      ></b-form-select
                      >&nbsp; รายการ
                    </label>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6"></div>
              </div>
            </div>

          </div>

          <div class="table-responsive mb-0">
            <b-table
              :items="rowsRequis"
              :fields="fields"
              responsive="true"
              :per-page="perPage"
              :current-page="1"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              ref="selectableTable"
              selectable
              :select-mode="selectMode"
              @row-selected="onRowSelected"
            >
            </b-table>
          </div>

          <div class="row">
            <!-- <div class="col">
              หน้า {{ currentPage }} จาก {{ this.totalPage }} ทั้งหมด
              {{ totalRecord }} รายการ
            </div> -->

            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRecord"
                    :per-page="perPage"
                    @change="handleChangePage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
            <br />
            <hr />
            <br />
          </div>
        </b-skeleton-wrapper>
      </div>

      <div class="row">
        <div class="col-md">
          <button class="btn btn-success float-end">ตกลง</button>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>
